<template>
  <welcome-base :header-path="promotionDetails.welcomeHeader" :title="welcomeTitle" :has-promotion="true"/>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import WelcomeBase from '@/pages/responsive-pages/non-authenticated/wine-and-dine/WelcomeBase';
import promotionConsts from '@/constants/promotions';

export default {
  name: 'WelcomeWithPromo',
  components: {
    WelcomeBase,
  },
  computed: {
    ...mapGetters('promotions', ['isPromotionEligible', 'isPercentageDiscountPromo']),
    ...mapState('promotions', ['promotion', 'benefitAmount', 'benefitPercentage']),
    promotionDetails() {
      return this.isPercentagePromo
        ? promotionConsts.getPercentageDiscountPromotionDetails(this.promotion, this.benefitAmount, this.benefitPercentage)
        : promotionConsts.getPromotionDetails(this.promotion);
    },
    welcomeTitle() {
      const mothsText = this.benefitAmount === 1 ? 'חודשיים' : `${this.benefitAmount + 1} חודשים `;
      return this.promotionDetails.welcomeTitle(mothsText);
    },
    isPercentagePromo() {
      return this.promotion && this.isPromotionEligible && this.isPercentageDiscountPromo;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
