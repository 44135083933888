<template>
  <div class="welcome-base">
    <div class="image-wrapper">
      <img class="image-container" :src="require(`@/assets/${headerPath}`)">
    </div>
    <div class="page-content">
      <div class="description">
        <div class="title">{{  title }}</div>
        <div class="subtitle">נעים מאוד, כיף שהגעת! נתחיל עם 4 שאלות קצרות שיעזרו לנו להכיר אותך</div>
      </div>
      <div class="bottom-page">
        <div class="button-container">
          <riseup-button class="button-to-signup" :color="'orange'"
                         :title="'בואו נצא לדרך'" @click="goToLQ"/>
        </div>
        <div class="already-registered">
          כבר הצטרפת לשירות?
          <span class="enter" v-on:click="goToHome">הכניסה מכאן</span>
        </div>
        <div class="become-partner-tool-tip">
          <base-tool-tip icon-path="/images/info-icon-blue.svg">
            <div class="info-text">
              <div>
                <span class="text">בן או בת הזוג כבר רשומים לשירות?</span>
                <br>
                <span class="popup-text" v-on:click="openPopup">ככה אפשר להצטרף אליהם למנוי משותף.</span>
              </div>
            </div>
          </base-tool-tip>
        </div>
        <div class="ts-and-cs">
          <span class="link" v-on:click="openTerms">למדיניות הפרטיות</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import router from '../../../../router';
import Segment from '../../../../Segment';
import questions from './lead-qualification/consts/questions';
import BaseToolTip from '../../../../base-components/onboarding/BaseToolTip.vue';
import InvitePartnerHelpPopup from '../../../../base-components/onboarding/help-popups/InvitePartnerHelpPopup.vue';

export default {
  name: 'WelcomeBase',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    BaseToolTip,
  },
  props: {
    headerPath: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    hasPromotion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    async goToLQ() {
      Segment.trackUserInteraction('SignupClicked');
      Segment.trackUserInteractionToPixel('SC');
      Segment.trackUserInteraction('SignupClicked_Marketing');
      router.push({ path: `/signup/lq/question/${questions.FIRST_QUESTION_NAME}`, query: this.$route.query });
    },
    async goToHome() {
      router.push({ path: '/sr', query: { ...this.$route.query, returning: true } });
    },
    async openTerms() {
      window.open('https://www.riseup.co.il/privacy-policy', '_blank');
    },
    openPopup() {
      Segment.trackUserInteraction('HowToAddPartnerExplanationLinkClicked');

      this.openModal({
        component: InvitePartnerHelpPopup,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/mixins';

  .welcome-base {
    @include white-page-frame;
    height: 100%;

    .image-wrapper {
      width: 100%;
      .image-container {
        width: 100%;
      }
    }

    .page-content {
      @include responsive-padding;
      margin-top: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .description {
        color: $riseup_black;
        .title {
          font-size: 22px;
          line-height: 1.27;
          width: 100%;
          margin-bottom: 15px;
          font-weight: bold;
        }

        .subtitle {
          font-size: 18px;
          line-height: 1.5;

          .bold {
            font-weight: bold;
          }
        }
      }

      .button-container {
        @include responsive-padding-top-bottom;

        .button-to-signup {
          width: 100%;
          white-space: nowrap;
        }
      }

      .already-registered {
        margin-bottom: 40px;
        color: $riseup_blue;
        text-align: center;

        .enter {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .become-partner-tool-tip {
        width: 100%;
        margin-bottom: 30px;

        .info-text {
          display: flex;
          align-items: center;
          font-family: SimplerPro;
          font-size: 14px;
          line-height: 20px;
          height: 100%;

          .text{
          }

          .popup-text{
            font-weight: bold;
            color: $riseup_blue;
            cursor: pointer;
          }
        }
      }

      .ts-and-cs {
        margin-bottom: 30px;
        color: $riseup_blue;
        text-align: center;

        .link {
          font-size: 14px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

</style>
