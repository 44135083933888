<template>
  <welcome-base :header-path="'introduction/intro_image_new_smaller.png'" :title="title"/>
</template>

<script>
import WelcomeBase from '@/pages/responsive-pages/non-authenticated/wine-and-dine/WelcomeBase';

export default {
  name: 'Welcome',
  components: {
    WelcomeBase,
  },
  props: {
    referralData: {
      type: Object,
    },
  },
  data() {
    return {
      showUpdatedText: false,
    };
  },
  computed: {
    title() {
      return 'אלפים כבר התחילו לשלוט בהוצאות ולחסוך עם RiseUp, עכשיו תורך!';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
