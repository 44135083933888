<template>
  <colored-popup
    :class="'warning'"
    :closeAction="closePopup">

    <template v-slot:top-content>
      <div class="top-content">
        <div class="title">הצטרפות למנוי משותף</div>
        <div class="title-description">השותף או השותפה שרשומים כבר לשירות צריכים להזמין אותך למנוי ב-2 צעדים פשוטים.</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="bottom-content">
        <div>
          <numbered-bullet number="1" variant="yellow" class="bullet"></numbered-bullet>
          <span>שולחים לבן או בת הזוג בקשה להצטרף לתזרים המשותף ואת הלינק המצורף</span>
        </div>
        <riseup-button
          class="button"
          title="לינק לשותפ.ה"
          :icon="require('@/assets/icons/chat_icon_small_white.svg')"
          size="slim"
          :action="requestPartnerInvite">
        </riseup-button>
        <div>
          <numbered-bullet number="2" variant="yellow" class="bullet"></numbered-bullet>
          <span>אחרי שיכנסו ללינק, השותף או השותפה ישלחו לך הזמנה להצטרף לתזרים.</span>
          <br><br>
          <span>וזהו, אתם על זה ביחד! 💪</span>
        </div>
      </div>
    </template>
  </colored-popup>
</template>

<script>

import Segment from '@/Segment';
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'InvitePartnerHelpPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    NumberedBullet: BaseUI.NumberedBullet,
  },
  computed: {
    invitePartnerMessage() {
      return encodeURIComponent('  היי! כדי שנוכל להיות בתזרים רייזאפ משותף,\n'
        + '  אצטרך לקבל ממך הזמנה ✉️.\n'
        + '\n'
        + 'אפשר ללחוץ על הלינק המצורף ולשלוח לי אחת  👇\n'
        + '\n'
        + 'https://input.riseup.co.il/invite-member');
    },
  },

  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closePopup() {
      Segment.trackUserInteraction('AddPartnerPopupClosed');
      this.closeModal();
    },
    requestPartnerInvite() {
      Segment.trackUserInteraction('RequestPartnerInviteClicked');
      window.open(`https://api.whatsapp.com/send?text=${this.invitePartnerMessage}`, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .top-content {
    text-align: right;
    line-height: 28px;
    .title{
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    .title-description{
      font-size: 22px;
      font-weight: 700;
    }
  }
  .bottom-content {
    font-size: 18px;
    text-align: right;
    line-height: 26px;
    .bold{
        font-weight: bold;
    }
    .button{
      margin: 30px 0 40px;
    }
    .bullet{
      margin-bottom: 20px;
    }
  }

</style>
