<template>
  <loader v-if="loading"/>
  <div v-else>
    <welcome-with-promo v-if="promotion && isPromotionEligible"/>
    <welcome v-else :referralData="referralData"/>
  </div>
</template>

<script>
import Loader from '@/base-components/Loader';
import { mapActions, mapGetters, mapState } from 'vuex';
import WelcomeWithPromo from '@/pages/responsive-pages/non-authenticated/wine-and-dine/WelcomeWithPromo';
import Welcome from '@/pages/responsive-pages/non-authenticated/wine-and-dine/Welcome';
import SumsumApi from '@/api/SumsumApi';
import Segment from '../../../../Segment';
import config from '../../../../config';

export default {
  name: 'WineAndDineFlow',
  components: {
    Welcome,
    Loader,
    WelcomeWithPromo,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    referralData: {
      type: Object,
    },
    promoCode: {
      type: String,
    },
  },
  async created() {
    this.loading = true;
    Segment.trackUserGotToPixel('SE');
    Segment.trackUserGot('SignupEntered_Marketing');
    this.fetchUtmParamsFromRoute();
    this.saveReferrer(this.utmParams);
    if (this.promoCode) {
      await this.fetchPromotion(this.promoCode);
      if (this.promotion && !this.isPromotionEligible) {
        Segment.trackUserGot('ExpiredPromotionUsed', { promotion: this.promotion, promoCode: this.promoCode });
      }
    }
    Segment.trackUserGot('SignupEntered', { ...this.utmParams, promotion: this.promotion, icrc: this.icrcParam });
    if (this.icrcParam) {
      SumsumApi.sendAnalyticsSignupByAmbassador('ambassador_SignupEntered', this.icrcParam);
    }
    this.loading = false;
  },
  computed: {
    ...mapState('promotions', ['promotion']),
    ...mapState('signup', ['utmParams', 'icrcParam']),
    ...mapGetters('promotions', ['isPromotionEligible']),
  },
  methods: {
    ...mapActions('signup', ['fetchUtmParamsFromRoute']),
    ...mapActions('promotions', ['fetchPromotion']),
    saveReferrer(utmParams) {
      if (utmParams.utm_term) {
        localStorage.setItem('riseup.utm_referrer_eoy', utmParams.utm_term);
      }
      if (this.referralData) {
        const { cookieDomain } = config.get();
        this.$cookies.set('referralToken', this.referralData.referralToken, '30d', '/', cookieDomain, true, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/mixins';

  .welcome {
    @include white-page-frame;
    height: 100%;

    .image-container {
      width: 100%;
    }

    .page-content {
      @include responsive-padding;
      margin-top: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .description {
        .title {
          font-size: 22px;
          line-height: 1.27;
          width: 100%;
          margin-bottom: 15px;
          font-weight: bold;
        }

        .subtitle {
          font-size: 18px;
          line-height: 1.5;

          .bold {
            font-weight: bold;
          }
        }
      }

      .button-container {
        @include responsive-padding-top-bottom;

        .button-to-signup {
          width: 100%;
          white-space: nowrap;
        }
      }

      .already-registered {
        margin-bottom: 30px;
        color: $riseup_light_blue;
        text-align: center;

        .enter {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

</style>
